
._2oZr3 ul {
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    border: 1px solid #e7e7e7;
    background-color: #eee;
  }
  
  ._2oZr3 li {
    float: left;
  }
  
  ._2oZr3 li a {
    display: block;
    color: #333;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }
  
  ._2oZr3 li a:hover {
    background-color: #ccc;
  }
  
